import _ from 'lodash'

function isEmptyValue(value) {
  return (
    _.isNil(value) ||
    (_.isString(value) && _.trim(value) === '') ||
    (_.isArray(value) && _.isEmpty(value)) ||
    (_.isPlainObject(value) && _.isEmpty(value))
  )
}

export function removeEmptyFields(obj) {
  if (!_.isObject(obj) || _.isDate(obj) || _.isRegExp(obj)) {
    return obj
  }

  return _.transform(obj, (result, value, key) => {
    const cleanedValue = removeEmptyFields(value)
    if (!isEmptyValue(cleanedValue)) {
      // @ts-ignore
      result[key] = cleanedValue
    }
  })
}
