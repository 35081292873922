import { Box, DataGrid, GridToolbarContainer, GridToolbarFilterButton } from '@papertrail/styleguide'
import CustomChip from '../../atoms/chip'
import React, { useMemo, useState } from 'react'
import { setInvitation } from '../../../state/slices/invitation'
import { useDispatch } from 'react-redux'
import { ManagerGridTypes } from '../../../types'
import { useParams } from 'react-router-dom'
import { Menu, MenuItem } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'

type Props = {
  data
  setPaginationModel
  onActionsClick
}

type Params = {
  id: string
}

const ActionButtonComponent = ({ onClick }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          height: 50,
          cursor: 'pointer'
        }}
        onClick={handleClick} // Clique na célula inteira para abrir o menu
      >
        <MoreVertIcon />
      </Box>
      <Menu
        id="action-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
            borderRadius: '8px',
            fontFamily: 'Poppins'
          }
        }}>
        <MenuItem
          onClick={() => {
            onClick('viewEdit')
            handleClose()
          }}>
          View or Edit Partnership
        </MenuItem>
      </Menu>
    </>
  )
}

const PartnershipGrid = (props: Props) => {
  const params = useParams<Params>()
  const subscriptionId = params.id
  const { data, setPaginationModel, onActionsClick } = props
  const pagination = { page: data.meta.pagination.current_page - 1, pageSize: 10 }
  const dispatch = useDispatch()

  const getPartnerFullName = ({ first_name, last_name }) => {
    return `${first_name} ${last_name}`
  }

  const isHost = (partnership) => {
    return subscriptionId === partnership.host_subscription.uuid
  }

  const handleActionClick = (action, partnership) => {
    if (action === 'viewEdit') {
      onActionsClick(partnership)
    }
  }

  const formattedData = useMemo(() => {
    return data?.data?.map((partnership) => ({
      id: partnership.id,
      Name: getPartnerFullName(partnership[isHost(partnership) ? 'consumer_contact' : 'host_contact']),
      Status: partnership.status,
      'Last Updated': partnership.updated_at,
      'Contact Name': partnership[isHost(partnership) ? 'consumer_subscription' : 'host_subscription'].name,
      'Contact Email': partnership[isHost(partnership) ? 'consumer_contact' : 'host_contact'].email,
      Actions: <ActionButtonComponent onClick={(action) => handleActionClick(action, partnership)} />
    }))
  }, [data, subscriptionId])

  const renderHeader = (params) => {
    const cellStyle = {
      fontFamily: 'Poppins',
      fontSize: '14px',
      fontWeight: '500',
      color: 'rgba(0, 0, 0, 0.87)'
    }

    return (
      <div data-testid={params.field} style={cellStyle}>
        {params.field}
      </div>
    )
  }

  const getColor = (value) => {
    switch (value) {
      case ManagerGridTypes.SENT:
        return '#007AFF'
      default:
        return '#00A354'
    }
  }

  const renderCell = (params) => {
    const cellStyle = {
      fontFamily: 'Poppins',
      fontSize: '14px',
      fontWeight: '400',
      color: 'rgba(0, 0, 0, 0.87)',
      cursor: 'pointer'
    }

    if (params.field === 'Actions') {
      return (
        <div style={cellStyle}>
          <ActionButtonComponent onClick={(action) => handleActionClick(action, params.row)} />
        </div>
      )
    }

    if (params.field === 'Status') {
      return <CustomChip status={params.value} color={getColor(params.value)} />
    }

    if (params.field === 'Last Updated') {
      return (
        <div data-testid={params.value} style={cellStyle}>
          {formatDate(params.value)}
        </div>
      )
    }

    return (
      <div data-testid={params.value} style={cellStyle}>
        {params.value}
      </div>
    )
  }

  const formatDate = (dateString) => {
    // Create a new Date object from the input string
    const date = new Date(dateString)

    // Extract the day, month, and year
    const day = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth() + 1).padStart(2, '0') // Months are zero-indexed
    const year = date.getFullYear()

    // Format the date as DD/MM/YYYY
    return `${day}/${month}/${year}`
  }

  const customToolbar = () => {
    return (
      <GridToolbarContainer>
        <Box sx={{ padding: '0 10px', fontFamily: 'Poppins' }}>
          <GridToolbarFilterButton />
        </Box>
      </GridToolbarContainer>
    )
  }

  const onPartnerClicked = (e) => {
    // dispatch set partnership
    dispatch(setInvitation(data.data.find((item) => item.id === e)))
  }

  const getFlexRatio = (field) => {
    switch (field) {
      case 'Name':
      case 'Contact Email':
      case 'Contact Name':
        return 1

      case 'Status':
      case 'Actions':
        return 0.4

      default:
        return 0.6
    }
  }

  return (
    <Box padding={2}>
      <DataGrid
        loading={false}
        meta={{
          meta: data.meta,
          pagination: pagination
        }}
        rowHeight={52}
        initialData={formattedData}
        columns={Object.keys(formattedData[0]).map((field, index) => ({
          field: field,
          flex: getFlexRatio(field),
          headerAlign: field === 'Actions' ? 'right' : 'left',
          align: field === 'Actions' ? 'right' : 'left',
          renderHeader: (params) => renderHeader(params),
          renderCell: (params) => renderCell(params),
          renderEditCell: (params) => renderCell(params)
        }))}
        filters={null}
        check={false}
        pinnedColumns={{ left: [] }}
        setPaginationModel={setPaginationModel}
        onRowClicked={onPartnerClicked}
        customToolbar={customToolbar}
        customSlotProps={{
          pagination: {
            SelectProps: {
              MenuProps: {
                PaperProps: {
                  sx: {
                    '& .MuiMenuItem-root': {
                      fontSize: '12px',
                      color: 'rgba(0, 0, 0, 0.6)',
                      fontFamily: 'Poppins'
                    }
                  }
                }
              }
            },
            sx: {
              '& .MuiTablePagination-selectLabel': {
                fontSize: '12px',
                color: 'rgba(0, 0, 0, 0.6)',
                fontFamily: 'Poppins'
              },
              '& .MuiTablePagination-select': {
                fontSize: '12px',
                fontFamily: 'Poppins',
                color: 'rgba(0, 0, 0, 0.87)',
                lineHeight: 1,
                paddingTop: 1.5
              },
              '& .MuiTablePagination-displayedRows': {
                fontSize: '12px',
                fontFamily: 'Poppins',
                color: 'rgba(0, 0, 0, 0.87)'
              }
            }
          }
        }}></DataGrid>
    </Box>
  )
}

export default PartnershipGrid
