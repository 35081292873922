import React, { useEffect, useState } from 'react'
import { Box, Typography, List, ListItem, ListItemIcon, ListItemText, Avatar } from '@mui/material'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../state/store'

const ConfirmDialog = () => {
  const { inviteSupplier, folders } = useSelector((state: RootState) => state.invitation)
  const [folderName, setFolderName] = useState('')
  const numberedCircles = (number) => (
    <Avatar
      sx={{
        bgcolor: 'black',
        color: 'white',
        width: 24,
        height: 24,
        fontSize: '14px',
        fontWeight: 'bold'
      }}>
      {number}
    </Avatar>
  )

  useEffect(() => {
    if (folders && inviteSupplier.host_contact.folder_uuid) {
      setFolderName(findFolderNameById(folders, inviteSupplier.host_contact.folder_uuid))
    }
  }, [folders, inviteSupplier])

  function findFolderNameById(folders, folderId) {
    // Base case: If folders is not an array or is empty, return null
    if (!Array.isArray(folders) || folders.length === 0) {
      return null
    }

    for (const folder of folders) {
      // Check if the current folder's id matches the folderId
      if (folder.id === folderId) {
        return folder.name
      }

      // If the folder has children, recursively search in the children
      if (folder.children && folder.children.length > 0) {
        const result = findFolderNameById(folder.children, folderId)
        if (result) {
          return result
        }
      }
    }

    // If no folder is found, return null
    return null
  }

  return (
    <Box>
      <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
        What This Means for You:
      </Typography>
      <List>
        <ListItem alignItems="flex-start">
          <ListItemIcon>{numberedCircles(1)}</ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="body1">
                Setup Overview: You’re about to finish setting up a partnership with {inviteSupplier.company_name}.
                Their workspace will be placed in your {folderName} folder.
              </Typography>
            }
          />
        </ListItem>

        <ListItem alignItems="flex-start">
          <ListItemIcon>{numberedCircles(2)}</ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="body1">
                What Happens Next: The current contents of {folderName} will be shared to their workspace under the
                folder {inviteSupplier.host_contact.company_name} - New Records and will be accessible to both your
                users and their users.
              </Typography>
            }
          />
        </ListItem>

        <ListItem alignItems="flex-start">
          <ListItemIcon>{numberedCircles(3)}</ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="body1">
                Your Access: You will always retain access to all of these shared records in the future.
              </Typography>
            }
          />
        </ListItem>
      </List>
    </Box>
  )
}

export default ConfirmDialog
