import React, { useEffect, useState } from 'react'
import {
  Box,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography
} from '@mui/material'
import { SupplierContact } from '../../../../state/slices/types'
import {
  updateCountries,
  updateIndustries,
  updateInviteSupplier,
  updateInviteSupplierContact
} from '../../../../state/slices/invitation'
import { useCountriesApi, useIndustriesApi } from '../../../../hooks'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../state/store'
import SendIcon from '@mui/icons-material/Send'
import { useSnackbar } from '../../../providers/snackbar'

interface ClientDataDetailProps {
  onValidationErrors?: (errors: Record<string, string>) => void
  onSearchContact: (email: string) => void
  isFormEnabled: boolean
}

const ClientDataDetail: React.FC<ClientDataDetailProps> = ({ onValidationErrors, onSearchContact, isFormEnabled }) => {
  const maxCommentsLength = 200

  const dispatch = useDispatch()
  const { showSnackbar } = useSnackbar()
  const { inviteSupplier, countries, industries } = useSelector((state: RootState) => state.invitation)
  const [newCountries, loadCountries] = useCountriesApi()
  const [newIndustries, loadIndustries] = useIndustriesApi()
  const [errors, setErrors] = useState<Record<string, string>>({})

  const validateField = (name: string, value: string) => {
    let error = ''
    if (name === 'email') {
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
      if (!emailRegex.test(value)) {
        error = 'Invalid email address'
      }
    } else if (name === 'phone_number') {
      const phoneRegex = /[0-9()-+,]+$/
      if (!phoneRegex.test(value)) {
        error = 'Invalid phone number'
      }
    }
    return error
  }

  const handleContactInviteInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    const error = validateField(name, value)

    setErrors((prev) => ({
      ...prev,
      [name]: error
    }))

    if (onValidationErrors) {
      onValidationErrors({ ...errors, [name]: error })
    }

    dispatch(updateInviteSupplierContact({ key: name as keyof SupplierContact, value }))
  }

  const handleCountryChange = (event: SelectChangeEvent<string>) => {
    dispatch(updateInviteSupplierContact({ key: 'country_code', value: event.target.value }))
  }

  const handleIndustryChange = (event: SelectChangeEvent<string>) => {
    dispatch(updateInviteSupplier({ key: 'industry_uuid', value: event.target.value }))
  }

  const handleSearchContact = () => {
    const errorMessage = validateField('email', inviteSupplier.contact.email)
    const isInvalidEmail = !!errorMessage

    if (isInvalidEmail) {
      showSnackbar(errorMessage, 'error')
      return false
    }

    onSearchContact(inviteSupplier.contact.email)
  }

  useEffect(() => {
    if (!countries.length && !industries.length) {
      loadIndustries({})
      loadCountries({})
    }
  }, [])

  useEffect(() => {
    if (newCountries.isLoaded && newIndustries.isLoaded) {
      dispatch(updateCountries(newCountries.data))
      dispatch(updateIndustries(newIndustries.data))
    }
  }, [newCountries, newIndustries])

  return (
    <Box component="form" sx={{ flexGrow: 1 }}>
      <Grid container spacing={1}>
        <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Typography variant="body1" sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>
            Customer Information:
          </Typography>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="email"
                label="Customer Email Address"
                required
                type="email"
                variant="outlined"
                value={inviteSupplier.contact.email}
                onChange={handleContactInviteInputChange}
                error={!!errors.email}
                helperText={errors.email}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleSearchContact} edge="end">
                        <SendIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                name="first_name"
                label="Customer Name"
                required
                disabled={!isFormEnabled}
                variant="outlined"
                value={inviteSupplier?.contact?.first_name}
                onChange={handleContactInviteInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                disabled={!isFormEnabled}
                name="last_name"
                label="Customer Last Name"
                required
                variant="outlined"
                value={inviteSupplier.contact.last_name}
                onChange={handleContactInviteInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled={!isFormEnabled}
                name="phone_number"
                label="Customer Phone Number"
                required
                type="tel"
                variant="outlined"
                value={inviteSupplier.contact.phone_number}
                onChange={handleContactInviteInputChange}
                error={!!errors.phone_number}
                helperText={errors.phone_number}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined" required>
                <InputLabel disabled={!isFormEnabled}>Country</InputLabel>
                <Select
                  disabled={!isFormEnabled}
                  value={inviteSupplier.contact.country_code}
                  onChange={handleCountryChange}
                  label="Country">
                  {countries.map((country) => (
                    <MenuItem value={country.value} key={country.value}>
                      {country.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Typography variant="body1" sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>
            Customer's Company Information:
          </Typography>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                disabled={!isFormEnabled}
                fullWidth
                name="company_name"
                label="Customer's Company Name"
                required
                variant="outlined"
                value={inviteSupplier.company_name}
                onChange={(e) => dispatch(updateInviteSupplier({ key: 'company_name', value: e.target.value }))}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined" required>
                <InputLabel disabled={!isFormEnabled}>Customer's Company Industry</InputLabel>
                <Select
                  disabled={!isFormEnabled}
                  value={inviteSupplier.industry_uuid}
                  onChange={handleIndustryChange}
                  label="Customer's Company Industry">
                  {industries.map((industry) => (
                    <MenuItem value={industry.value} key={industry.value}>
                      {industry.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled={!isFormEnabled}
                label="Comments"
                variant="outlined"
                name="comments"
                multiline
                rows={4.1}
                value={inviteSupplier.comments}
                onChange={(e) => dispatch(updateInviteSupplier({ key: 'comments', value: e.target.value }))}
                helperText={`${inviteSupplier.comments.length}/${maxCommentsLength}`}
                inputProps={{ maxLength: maxCommentsLength }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default ClientDataDetail
