/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Button, Drawer, IconButton, Typography } from '@mui/material'
import React, { ReactNode, useMemo, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CloseIcon from '@mui/icons-material/Close'
import CustomStepper from '../stepper'
import FolderMappingDetail from '../detail/supplierReview/folderMapping'
import { StepItem } from 'src/types'
import { resetState } from '../../../state/slices/invitation'
import { useSnackbar } from '../../providers/snackbar'
import MapStates from '../detail/supplierReview/mapStates'
import PartnershipCreated from '../detail/supplierReview/partnershipCreated'
import Review from '../detail/supplierReview/review'
import { RootState } from '../../../state/store'
import { useParams } from 'react-router-dom'
import { useApiCompleteInvite, useApiPatchInvite } from '../../../hooks'
import ConfirmDialog from '../detail/supplierReview/confirmDialog'

interface IDrawerInvitePartnersProps {
  isOpened: boolean
  setIsOpened: (state: boolean) => any
  children?: ReactNode
}

enum STEPPER_ITEMS {
  REVIEW = 0,
  FOLDER_MAPPING = 1,
  STATE_MAPPING = 2,
  CONFIRM_DIALOG = 3,
  CREATE_PARTNERSHIP = 4
}

const DrawerSupplierReview: React.FC<IDrawerInvitePartnersProps> = ({
  isOpened,
  setIsOpened
}: IDrawerInvitePartnersProps) => {
  const [step, setStep] = useState(STEPPER_ITEMS.REVIEW)
  const { showSnackbar } = useSnackbar()
  const [initial, setInitial] = useState<boolean>(true)
  const dispatch = useDispatch()
  const { inviteSupplier } = useSelector((state: RootState) => state.invitation)
  const { id: subscriptionId } = useParams<{ id: string }>()
  const [_, updateInvite] = useApiPatchInvite(subscriptionId)
  const [, completeInvite] = useApiCompleteInvite(subscriptionId)
  const handleBackStep = () => handleChangeStep(-1)
  const handleNextStep = () => {
    if (step === 0 && initial === true) {
      setInitial(false)
    } else {
      handleChangeStep(1)
    }
  }

  const handleFinishInvitation = () => {
    setIsOpened(false)
    setInitial(true)
    dispatch(resetState())
    setStep(STEPPER_ITEMS.REVIEW)
    window.dispatchEvent(new Event('@invites/GET_ALL'))
  }

  const handleUpdateInvitation = async () => {
    try {
      await updateInvite(inviteSupplier.mappings, inviteSupplier.id)
      await setTimeout(() => {
        completeInvite(inviteSupplier.id)
      }, 300)
      handleNextStep()
    } catch (err) {
      showSnackbar(err.message, 'error')
    }
  }

  const steps = useMemo<StepItem[]>(() => {
    return [
      {
        label: 'Review',
        value: STEPPER_ITEMS.REVIEW,
        component: <Review initial={initial} />,
        description: `Please review the permissions ${inviteSupplier.company_name} wants to grant you in their account.`,
        controls: {
          back: {
            hidded: true
          },
          next: {
            onClick: handleNextStep
          }
        }
      },
      {
        label: 'Folder Mapping',
        value: STEPPER_ITEMS.FOLDER_MAPPING,
        component: <FolderMappingDetail />,
        description: 'Review where you mapped the clients workspace into your own folder structure',
        controls: {
          back: {
            hidded: false,
            onClick: handleBackStep
          },
          next: {
            disabled: null,
            onClick: handleNextStep
          }
        }
      },
      {
        label: 'State Mapping',
        value: STEPPER_ITEMS.STATE_MAPPING,
        component: <MapStates />,
        description: "Map the states from your clients' shared accounts to align with the states in your account.",
        controls: {
          back: {
            hidded: false,
            onClick: handleBackStep
          },
          next: {
            disabled: null,
            onClick: handleNextStep
          }
        }
      },
      {
        label: 'Partnership',
        value: STEPPER_ITEMS.CONFIRM_DIALOG,
        component: <ConfirmDialog />,
        description: `You are about to finalise the partnership setup with ${inviteSupplier.company_name}. Please review the details below and confirm before proceeding.`,
        controls: {
          back: {
            hidded: false,
            onClick: handleBackStep
          },
          next: {
            text: 'Confirm',
            disabled: null,
            onClick: handleUpdateInvitation
          }
        }
      },
      {
        label: 'Setup Complete',
        value: STEPPER_ITEMS.CREATE_PARTNERSHIP,
        component: <PartnershipCreated />,
        description: `You have completed the Partnership setup. You can view or make changes to this setup in the "Active" tab.`,
        controls: {
          back: {
            hidded: true
          },
          next: {
            text: 'Close',
            onClick: handleFinishInvitation
          }
        }
      }
    ]
  }, [initial, inviteSupplier])

  const selectedStep = useMemo(() => {
    return steps.find((item) => item.value === step)
  }, [steps, step])

  const handleChangeStep = (nextStep: number) => {
    const nextStepIndex = step + nextStep
    if (nextStepIndex < 0 || nextStepIndex > steps.length) return false
    setStep((currentStep) => currentStep + nextStep)
  }

  const handleCloseDrawer = () => {
    dispatch(resetState())
    setInitial(true)
    setIsOpened(false)
    setStep(STEPPER_ITEMS.REVIEW)
  }

  return (
    <>
      <Drawer
        anchor="right"
        open={isOpened}
        onClose={handleCloseDrawer}
        PaperProps={{
          sx: {
            maxWidth: 1200
          }
        }}>
        <Box className="right-drawer">
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'center',
              height: 34
            }}>
            <Typography variant="h5">Partnership Setup</Typography>
            <IconButton onClick={handleCloseDrawer}>
              <CloseIcon color="primary" />
            </IconButton>
          </Box>

          <Box sx={{ display: 'flex' }}>
            <Box sx={{ width: '100%' }}>
              <Typography variant="h6">{selectedStep.label}</Typography>
              <Typography variant="body1">{selectedStep.description}</Typography>
            </Box>
            <Box sx={{ width: '100%' }}>
              <CustomStepper items={steps} activeStep={step} />
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1, mt: 2 }}>
                {!selectedStep.controls.back.hidded && (
                  <Button
                    disabled={selectedStep.controls.back.disabled}
                    variant="outlined"
                    onClick={selectedStep.controls.back.onClick}>
                    {selectedStep.controls.back.text || 'Back'}
                  </Button>
                )}
                {!selectedStep.controls.next.hidded && (
                  <Button
                    disabled={selectedStep.controls.next.disabled}
                    variant="contained"
                    color="primary"
                    onClick={selectedStep.controls.next.onClick}>
                    {selectedStep.controls.next.text || 'Next'}
                  </Button>
                )}
              </Box>
            </Box>
          </Box>

          {selectedStep.component}
        </Box>
      </Drawer>
    </>
  )
}

export default DrawerSupplierReview
